<template>
  <nav
    id="menu-header" class="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3"
  >
    <div
      class="container px-4 mx-auto flex flex-wrap items-center justify-between"
    >
      <div
        class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
      >
        <a
          class="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white"
          href="#"
          >Brota Na Pico</a
        ><button
          class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          v-on:click="toggleNavbar()"
        >
          <i class="text-white fas fa-bars"></i>
        </button>
      </div>
      <div
        class="lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none"
        v-bind:class="{'hidden': !showMenu, 'block': showMenu}"
      >
<!--        <ul class="flex flex-col lg:flex-row list-none mr-auto">-->
<!--          <li class="flex items-center">-->
<!--            <a-->
<!--              class="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"-->
<!--              href="#festa"-->
<!--              >-->
<!--              A Festa</a-->
<!--            >-->
<!--          </li>-->
<!--          <li class="flex items-center">-->
<!--            <a-->
<!--                class="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"-->
<!--                href="#dj-casa"-->
<!--            >-->
<!--              DJ da Casa</a-->
<!--            >-->
<!--          </li>-->
<!--        </ul>-->
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
          <li class="flex items-center">
            <a
              class="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              href="https://www.instagram.com/brotanapico"
              ><i
                class="lg:text-gray-300 text-gray-500 fab fa-instagram text-lg leading-lg "
              ></i
              ><span class="lg:hidden inline-block ml-2">Share</span></a
            >
          </li>
          <li class="flex items-center">
            <a
              class="bg-white text-gray-800 active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
              href="#vem-pro-brota"
              style="transition: all 0.15s ease 0s;"
            >
              <i class="fas fa-arrow-alt-circle-down"></i> Em Breve
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { useParties } from "@/stores/partiesStore.js";

const showMenu = ref(false);
const partiesStore = useParties();

onMounted(() => {
  const nav = document.getElementById('menu-header');
  const festa = document.getElementById('parceiro');

  window.addEventListener('scroll', () => {
    const festaPosition = festa.getBoundingClientRect().top;
    const navHeight = nav.offsetHeight;

    // Quando a div "festa" chega no topo do <nav>
    if (festaPosition <= navHeight) {
      nav.classList.add('bg-menu-header');
    } else {
      nav.classList.remove('bg-menu-header');
    }
  });
})
const toggleNavbar = () => {
  showMenu.value = !showMenu.value;
}
</script>
<style scoped>
  .bg-menu-header {
    background-color: #000;
    opacity: 90;
  }
</style>